.form-control {
    resize: none;
}

.form-control:focus {
    border-color: var(--primary-color);
    box-shadow: var(--box-shadow-input);
}

[data-theme="dark"] .input-group {
    --bs-body-bg: #212529;
}