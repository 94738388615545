@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --background-color: #fff;
  --bg-color-secondary: #F8F9FA;
  --bg-color-kanban: rgb(233, 233, 233);
  --font-color: #242424;
  --font-color-inverse: #f3f3f3;
  --tab-color: #F8F9FA;
  --tab-color-checked: #F8F9FA;
  --tab-background: linear-gradient(to top, #F8F9FA, #fff);
  --chart-font-color: #3d3d3d;
  --nav-color: #2B3035;
  --primary-color: #F68D2E;
  --box-shadow-input: 0 0 0 0.05rem #F68D2E90, 0 0 0 0.07rem #F68D2E95;
  --color-scrollbar: #d1d1d1;
  --side-bar-color: #334166;
  --line-border-color: rgba(0, 0, 0, .12);
  --icon-color: #585858;
  --hover-icon-color: rgba(0, 0, 0, .1);
}

[data-theme="dark"] {
  --background-color: #212529;
  --bg-color-secondary: #2B3035;
  --bg-color-kanban: #161C22;
  --font-color: #f3f3f3;
  --font-color-inverse: #242424;
  --tab-color: #2B3035;
  --tab-color-checked: #f68b2e0a;
  --tab-background: linear-gradient(to top, #2B3035, #212529);
  --chart-font-color: #fff;
  --bs-body-bg: #2B3035;
  --side-bar-color: var(--bg-color-secondary);
  --line-border-color: rgba(103, 112, 121, 0.4);
  --icon-color: #AAADB1;
  --hover-icon-color: rgba(255, 255, 255, .1);
}

body {
  font-family: "Montserrat", 'Segoe UI', 'Roboto', 'Oxygen';
  padding: 0;
  margin: 0;
}

input {
  font-family: "Montserrat", 'Segoe UI', 'Roboto', 'Oxygen';
}

div {
  font-family: "Montserrat", 'Segoe UI', 'Roboto', 'Oxygen';
}

p {
  font-family: "Montserrat", 'Segoe UI', 'Roboto', 'Oxygen' !important;
}

.modal-backdrop {
  z-index: 1300 !important;
}

.modal {
  --bs-modal-zindex: 1301;
}

.nav-tabs .nav-link {
  color: var(--font-color);
  font-size: 14px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-top-color: #f68d2e;
  border-right-color: #f68d2e;
  border-left-color: #f68d2e;
  background: var(--tab-color-checked);
  border-bottom: var(--tab-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nav-tabs {
  background: var(--tab-background);
}

.apexcharts-yaxis-label,
.apexcharts-xaxis-label,
.apexcharts-title-text,
.apexcharts-menu-icon svg,
.apexcharts-reset-icon svg,
.apexcharts-zoom-icon svg,
.apexcharts-zoomin-icon svg,
.apexcharts-zoomout-icon svg {
  fill: var(--chart-font-color) !important;
}

.apexcharts-legend-text {
  color: var(--chart-font-color) !important;
}

.bg-body-tertiary {
  background-color: var(--nav-color) !important;
}

/*navbar*/
.nav-link {
  color: #c2c2c4;
}

.nav-link:focus,
.nav-link:hover,
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--font-color);
  ;
}

/*scrollbar*/
::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-scrollbar);
  border-radius: 4px;
}

.card-body,
.modal-content {
  color: var(--font-color) !important;
}