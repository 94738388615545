.custom_select {
    padding: 0px !important;
}

.css-1udlyto-control,
.css-1iw9pji-control {
    background-color: var(--bs-body-bg) !important;
}

.custom_select:focus-within {
    border-color: var(--primary-color);
    box-shadow: var(--box-shadow-input)
}

.custom_select.is-valid {
    padding-right: calc(1.5em + 0.25rem) !important;
}

.custom_select.is-invalid {
    padding-right: calc(1.5em + 0.25rem) !important;
}

.css-1a7tle7-singleValue {
    color: var(--font-color) !important;
}

.css-1n6sfyn-MenuList {
    background-color: var(--background-color);
}