:root {
    --hover-background-color: rgb(245, 245, 245);
}

[data-theme="dark"] {
    --hover-background-color: rgba(0, 0, 0, 0.16);
}

/* Estilização das fonts da tabela */
.p-datatable table {
    font-family: "Montserrat", 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

/* Cabeçalho, corpo e rodapé da tabela */
.p-paginator,
.p-datatable .p-datatable-tbody>tr,
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-datatable-thead>tr>th {
    background: var(--background-color);
    color: var(--font-color);
}

/* Cor do cabeçalho */
.p-datatable .p-datatable-header {
    background: var(--font-color);
}

/* Estilos para tabelas pequenas */
.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.6rem 0.5rem;
}

/* Overlay de carregamento */
.p-datatable .p-datatable-loading-overlay {
    border-radius: 4px;
}

/* Conteúdo do cabeçalho da coluna */
.p-datatable .p-column-header-content {
    padding: 0.2rem;
}

/* Paginação */
.p-paginator .p-paginator-current {
    font-size: 12px;
    border: none;
    color: var(--font-color);
    height: 1rem;
    padding: 1rem;
}

.p-datatable .p-paginator-bottom {
    max-height: 3.2rem;
    border-width: 0 0 1px 0;
    border-radius: 4px;
    border-color: var(--line-border-color);
}

/* Título das colunas */
.p-column-title {
    margin-right: 4px;
}

.p-datatable .p-column-title {
    font-weight: 500;
    font-size: 12px;
    padding: 8px 0px;
}

/* Removendo sombras ao focar na ordenação */
.p-datatable .p-sortable-column:focus {
    box-shadow: none;
}

/* Botões de paginação */
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    border: none;
    color: var(--font-color);
    min-width: 2rem;
    height: 2rem;
    margin: .143rem;
    border-radius: 50%;
}

/* Ajuste do espaçamento das células para tabelas pequenas */
.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    padding: 0.85rem;
}

/* Mensagem quando a tabela está vazia */
.p-datatable-emptymessage {
    font-size: 12px;
}

/* Estilos dos botões */
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--font-color);
}

.p-button {
    font-family: "Montserrat", 'Segoe UI', 'Roboto' !important;
    border: none !important;
    background-color: var(--primary-color) !important;
    color: #fff !important;
    box-shadow: none !important;
    padding: 4px !important;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover,
.p-button.p-button-outlined:enabled:hover {
    background: #e07f29 !important;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover,
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon,
.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    color: var(--primary-color);
}

/* Estilo do dropdown */
.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: var(--primary-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 14px;
    padding: 0.5rem 0.75rem;
}

/* Menu de filtros */
.p-column-filter-menu {
    margin-left: 5px;
}

/* Bordas arredondadas para o wrapper da tabela */
.p-datatable-wrapper {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

/*cor do ícone de filtro e ordenação*/
.p-column-filter-menu-button,
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: var(--icon-color);
}

/*cor do hover do ícone de filtro*/
.p-column-filter-menu-button:hover,
.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: var(--hover-icon-color);
    color: var(--icon-color);
}

/*hover do cabeçalho e linhas*/
.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover,
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    color: var(--font-color);
    background: var(--hover-background-color);
}

/*Cor da borda das linhas*/
tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: var(--line-border-color) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--font-color);
    background: var(--hover-background-color);
}

.p-column-filter-overlay {
    background-color: var(--bg-color-secondary);
}

.p-dropdown,
.p-dropdown:not(.p-disabled):hover {
    background-color: var(--background-color);
    color: var(--font-color);
    border-color: var(--line-border-color);
}

.p-dropdown .p-inputtext,
.p-dropdown .p-dropdown-trigger {
    color: var(--font-color);
}

.p-inputtext,
.p-inputtext:enabled:hover,
.p-dropdown .p-inputtext {
    font-family: "Montserrat", 'Segoe UI', 'Roboto' !important;
    font-size: 14px;
    padding: 0.5rem 0.75rem;
    background: var(--background-color);
    border-color: var(--line-border-color);
    color: var(--font-color);
}

.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    border-color: var(--primary-color);
    box-shadow: none;
}

.p-dropdown-panel,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 14px;
    background-color: var(--background-color);
    color: var(--font-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: var(--hover-background-color);
    color: var(--font-color)
}

/*botão de expandir */
.p-datatable .p-row-toggler {
    color: var(--icon-color) !important;
}

.p-datatable .p-row-toggler:hover {
    background-color: var(--hover-icon-color) !important;
}